import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import { Wrapper } from "../components/style/global-styles";
import Box from "../components/box";
import PostBodySections from "../components/post-body-sections";
import { PostTemplateHero } from "../components/post-template-hero";
import { changeTheme, clampBuilder } from "../functions/util";
import { Helmet } from "react-helmet";
import useMeta from "../hooks/use-meta";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicPost: { data },
  },
}) {
  const { site_url } = useMeta();

  useEffect(() => {
    if (data.theme) {
      changeTheme("#000000", "#ffffff");
    }
    return () => {
      changeTheme(null, null);
    };
  }, [data.theme]);

  const showDetails = data.project_details.raw.length > 0;

  return (
    <div style={{ "--post-accent-color": data.accent_color }}>
      {data.on_the_sly && (
        <Helmet>
          <meta name="robots" content="noimageindex, nofollow, nosnippet" />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <Helmet
        meta={[
          { property: "og:type", content: "website" },
          { property: "og:url", content: `${site_url}${data.url}` },
          { property: "og:title", content: data.title },
          { property: "og:description", content: data.project_details.text },
          { property: "og:image", content: data.marquee.url },
          // Twitter
          { property: "twitter:card", content: "summary_large_image" },
          { property: "twitter:url", content: `${site_url}${data.url}` },
          { property: "twitter:title", content: data.title },
          {
            property: "twitter:description",
            content: data.project_details.text,
          },
          { property: "twitter:image", content: data.marquee.url },
        ]}
      />
      <PostTemplateHero data={data} />
      {showDetails && (
        <Wrapper>
          <PostDetails>
            <PostDetailsCol>
              <li>
                <h4 className="sequel">Project Details</h4>
              </li>
              <li>
                <RichText render={data.project_details.raw} />
              </li>
            </PostDetailsCol>
            <PostDetailsCol className="right-col">
              <li>
                <h4 className="sequel">Credits</h4>
              </li>
              <li>
                <p className="strong">Role:</p>
                <RichText render={data.credits_role.raw} />
              </li>
              <li>
                <p className="strong">Team:</p>
                <RichText render={data.credits_team.raw} />
                {data.work_for.raw && (
                  <h6 className="work-for">
                    <p>Work created for</p>{" "}
                    <RichText render={data.work_for.raw} />
                  </h6>
                )}
              </li>
            </PostDetailsCol>
          </PostDetails>
        </Wrapper>
      )}
      <PostBodySections body={data.body} />
    </div>
  );
}

const PostDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  margin: 6vw 0 6vw;
  /* margin-bottom: 2rem; // gap override */

  @media ${(props) => props.theme.device.laptop} {
    flex-direction: row;
    /* margin-bottom: 0; // gap override */

    ul:first-child {
      margin-right: 10vw; // gap override
    }
    /* gap: 10vw; */
  }

  h1 {
    font-size: ${clampBuilder(2, 4.25)};
    line-height: 1.2;
  }

  h4 {
    color: var(--post-accent-color);
  }

  p {
    font-size: 0.85rem;
  }
`;

const PostDetailsCol = styled.ul`
  display: flex;
  /* gap: 3vw; */
  flex-direction: column;
  height: fit-content;
  position: relative;
  padding: 3rem 0;
  margin-bottom: 3vw; // gap override

  @media ${(props) => props.theme.device.tablet} {
    padding: 4vw 0;
  }

  &::before,
  &::after {
    content: "—";
    color: white;
    mix-blend-mode: difference;
    position: absolute;
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }

  p {
    font-size: 1rem;
  }

  li {
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    margin: 0;
    margin-bottom: 3vw; // gap override

    > *,
    > * > * {
      margin-bottom: 2rem; // gap override
    }

    p {
      margin: 0;
    }
  }

  &.right-col {
    padding-top: 1rem;

    &:before {
      content: unset;
    }

    @media ${(props) => props.theme.device.laptop} {
      padding-top: 4vw;
      min-width: 30%;

      &:before {
        content: "—";
      }
    }

    li {
      /* gap: 0; */
    }

    p {
      font-size: 0.65rem;
    }
  }

  .work-for {
    margin-top: 2rem;

    & > * {
      font-family: "input-mono-condensed", monospace;
      display: inline;
    }
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      url
      first_publication_date
      last_publication_date
      dataRaw
      data {
        title
        theme
        on_the_sly
        body {
          ... on PrismicPostBodyText {
            id
            slice_type
            primary {
              title
              text_body {
                html
                text
                raw
              }
            }
          }
          ... on PrismicPostBodySingleImage {
            id
            slice_type
            primary {
              single_image_layout
              full_height
              single_image_title {
                raw
                text
              }
              image {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 2560
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
          ... on PrismicPostBodyDoubleImage {
            id
            slice_type
            primary {
              double_image_layout
              double_image_title {
                raw
                text
              }
              image_1 {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 1200
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              video_1_override {
                url
                id
              }
              image_2 {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 1200
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              video_2_override {
                url
                id
              }
              caption
              button {
                target
                raw
              }
            }
            slice_type
          }
          ... on PrismicPostBodyImageGrid {
            id
            slice_type
            items {
              caption {
                text
              }
              grid_image {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 900
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            primary {
              double_image_title {
                raw
                text
              }
            }
            slice_type
          }
          ... on PrismicPostBodyMediaGrid {
            id
            slice_type
            id
            items {
              caption {
                text
              }
              grid_media {
                id
                url
              }
            }
            primary {
              double_image_title {
                text
                raw
              }
            }
          }
          ... on PrismicPostBodySingleVideo {
            id
            slice_type
            primary {
              lightbox
              single_image_layout
              single_image_title {
                raw
                text
              }
              video {
                id
                url
              }
              lightbox_video_override {
                id
                url
              }
            }
            slice_type
          }
          ... on PrismicPostBodyDivider {
            id
            slice_type
            primary {
              spacing_top
              spacing_bottom
            }
          }
          ... on PrismicPostBodySvgImageLayout {
            id
            slice_type
            primary {
              full_height
              image {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 1440
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              inverted_image {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 1440
                ) {
                  ...GatsbyPrismicImageFluid
                }
              }
              single_image_layout
              single_image_title {
                text
                raw
              }
            }
          }
        }
        marquee {
          alt
          copyright
          url(imgixParams: { maxWidth: 1012 })
          thumbnails
          fluid(placeholderImgixParams: { blur: 1600, px: 1 }, maxWidth: 2560) {
            ...GatsbyPrismicImageFluid
          }
        }
        marquee_video_override {
          url
        }
        work_for {
          raw
        }
        project_details {
          text
          raw
        }
        description
        date
        credits_team {
          raw
        }
        credits_role {
          raw
        }
        brand_logo {
          alt
          copyright
          url
          thumbnails
        }
        brand_logo_svg_inverted {
          alt
          copyright
          url
          thumbnails
        }
        accent_color
      }
    }
  }
`;
