import React, { useEffect } from "react";
import { useAnimation, useReducedMotion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { motion } from "framer-motion";

export function LineAnimated() {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });
  const reduceMotion = useReducedMotion();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const lineVariants = {
    pre: { opacity: 0, width: "50%", y: -20 },
    visible: { opacity: 1, width: "100%", y: 0 },
    hidden: { opacity: 0, width: "50%", y: -20 },
  };

  return (
    <Line
      ref={ref}
      className="line"
      initial={reduceMotion ? "visible" : "hidden"}
      animate={reduceMotion ? "visible" : controls}
      variants={lineVariants}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.3, delay: 0.5 }}
    />
  );
}

export const Line = styled(motion.span)`
  height: 0.5rem;
  width: 100%;
  position: relative;
  display: inline-block;
  margin: 1rem 0;
  pointer-events: visible;
  z-index: 10;
  transform-origin: left center;
  mix-blend-mode: difference;

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background: white;
    z-index: 10;
  }
`;
