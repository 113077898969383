import React, { useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { hoverChange, stringToSlug } from "../functions/util";
import Box from "./box";
import MainModal from "./modal";
import { Wrapper } from "./style/global-styles";
import VideoCover from "./video-cover/video-cover";

export function PostSingleVideo({ data }) {
  const [modalVisible, handleModal] = useState(false);

  const videoOptions = {
    autoPlay: true,
    playsInline: true,
    preload: "auto",
    muted: true,
    loop: true,
  };

  const style = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    background: "black",
  };

  const source = [{ src: data.primary.video.url, type: "video/mp4" }];

  const showLightbox = data.primary.lightbox;
  const lightboxSource = [
    { src: data.primary.lightbox_video_override.url, type: "video/mp4" },
  ];

  return (
    <>
      {data.primary.single_image_title.text && (
        <SectionHeader>
          <h4 className="sequel">{data.primary.single_image_title.text}</h4>
        </SectionHeader>
      )}
      <VideoWrapper
        data-layout={stringToSlug(data.primary.single_image_layout)}
        data-lightbox={showLightbox}
        onClick={(e) => showLightbox && handleModal(true)}
        onMouseEnter={() => showLightbox && hoverChange("video")}
        onMouseLeave={() => showLightbox && hoverChange(null)}
      >
        <VideoCover
          videoOptions={videoOptions}
          source={source}
          remeasureOnWindowResize
        />
      </VideoWrapper>
      {showLightbox && (
        <MainModal modalVisible={modalVisible} handleModal={handleModal}>
          <ReactPlayer
            id={"videoFull"}
            style={style}
            width="100%"
            height="100%"
            loop
            playing
            controls
            muted={false}
            volume={0.2}
            url={lightboxSource ? lightboxSource : source}
          />
        </MainModal>
      )}
    </>
  );
}

const SectionHeader = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 2vw;

  h4 {
    color: var(--post-accent-color);
  }
`;

const VideoWrapper = styled(Box)`
  height: 66vw;
  width: 100vw;

  @media (hover: none) {
    position: relative;

    &[data-lightbox="true"] {
      &:active {
        &:after {
          transform: scale(0.9) translateY(-50%) translateX(-50%) rotate(0deg);
        }
      }

      &:after {
        content: "";
        width: 8rem;
        height: 8rem;
        opacity: 1;
        background: url("/svg/play.svg") no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) rotate(0deg);
        transform-origin: center center;
        transition: transform
            ${(props) => props.theme.animation.duration[300].css}
            ${(props) => props.theme.animation.timingFunction.css},
          opacity ${(props) => props.theme.animation.duration[100].css}
            ${(props) => props.theme.animation.timingFunction.css} !important;
      }
    }
  }

  &[data-layout="full-width"] {
    height: 66vw;
    width: 100vw;
    margin: 8vw 0;
  }

  &[data-layout="full-width-auto-height"] {
    height: auto;
    width: 100vw;
    margin: 8vw 0;
  }

  &[data-layout="wrapper-width"] {
    height: auto;
    width: 84vw;
    margin: 8vw;
  }
`;
