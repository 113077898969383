import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';

import {Wrapper} from './style/global-styles';
import Box from './box';
import ImageBox from './image-box';
import {animation} from '../data/baseTheme';
import AnimatedText from './animated-text';
import VideoCover from './video-cover/video-cover';

export function PostTemplateHero({data}) {
  const textVariants = {
    pre: {
      opacity: 0,
      rotate: '-3deg',
      y: '140%'
    },
    active: {
      opacity: 1,
      rotate: '0deg',
      y: '0%',
      transition: {
        duration: 0.9,
        ease: [animation.timingFunction.js]
      }
    }
  };

  return (
    <>
      <Wrapper>
        {data.title && (
          <PostHeader>
            {data.brand_logo && (
              <Image
                className={
                  data.brand_logo_svg_inverted.url ? 'uninverted-svg' : ''
                }
                src={data.brand_logo.url}
                variants={textVariants}
                initial='pre'
                animate='active'
                alt={data.brand_logo.alt}
              />
            )}
            {data.brand_logo_svg_inverted.url && (
              <Image
                className={'inverted-svg'}
                src={data.brand_logo_svg_inverted.url}
                variants={textVariants}
                initial='pre'
                animate='active'
                alt={data.brand_logo_svg_inverted.alt}
              />
            )}
            {data.title && <AnimatedText text={data.title} className='h2' />}
          </PostHeader>
        )}
      </Wrapper>
      {!data.marquee_video_override.url ? (
        <PostMarquee fluid={data.marquee.fluid} />
      ) : (
        <PostVideoMarquee
          videoOptions={{
            autoPlay: true,
            playsInline: true,
            preload: 'auto',
            muted: true,
            loop: true
          }}
          source={[{src: data.marquee_video_override.url, type: 'video/mp4'}]}
          remeasureOnWindowResize
        />
      )}
    </>
  );
}

const Image = styled(motion.img)`
  max-height: 6.25rem;
`;

const PostHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 8rem;
  padding-bottom: 6vw;
  text-align: center;

  img {
    width: 70px;
    margin-bottom: 2.75rem;
  }

  @media ${(props) => props.theme.device.tablet} {
    padding-top: 22vw;
    padding-bottom: 6vw;

    img {
      width: inherit;
    }
  }
`;

const PostVideoMarquee = styled(VideoCover)`
  display: flex;
  height: 52vw;
  width: 100vw;
`;

const PostMarquee = styled(ImageBox)`
  display: flex;
  height: 52vw;
  width: 100vw;
`;
