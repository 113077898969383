import React from 'react';
import styled from 'styled-components';
import Box from './box';
import { Wrapper } from './style/global-styles';
import { RichText } from 'prismic-reactjs';

export function PostText({ data: { primary } }) {
  return (
    <Wrapper>
      <Text>
        {primary.title && <h3>{primary.title}</h3>}
        {primary.text_body && <RichText render={primary.text_body.raw} />}
      </Text>
    </Wrapper>
  );
}

const Text = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  position: relative;
  margin: 6vmax 8vmin;
  text-align: center;

  h3 {
    max-width: 25em;
  }

  p {
    max-width: 60em;
  }
`;
