import React from 'react';
import styled from 'styled-components';
import { stringToSlug } from '../functions/util';
import Box from './box';
import ImageBox from './image-box';
import { Wrapper } from './style/global-styles';

export function PostSingleImage({ data }) {
  return (
    <>
      {data.primary.single_image_title.text && (
        <SectionHeader>
          <h4 className="sequel">{data.primary.single_image_title.text}</h4>
        </SectionHeader>
      )}
      <SinglePhotoWrapper
        data-layout={stringToSlug(data.primary.single_image_layout)}
        data-full-height={data.primary.full_height}
      >
        <ImageBox className="image" fluid={data.primary.image.fluid} />
      </SinglePhotoWrapper>
    </>
  );
}

const SectionHeader = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 6vw;

  h4 {
    color: var(--post-accent-color);
  }
`;

const SinglePhotoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  h4 {
    color: var(--post-accent-color);
  }

  &[data-layout='full-width'] {
    margin: 0 0 8vw;
    height: auto;
    width: 100vw;
  }

  &[data-layout='wrapper-width'] {
    margin: 0 8vw 8vw;
    height: auto;
    width: 84vw;
  }

  &[data-full-height='true'] {
    .image {
      height: 100vh;
      max-height: 100vw;
    }
  }
`;
