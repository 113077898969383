import React from 'react';
import { PostDoubleImage } from './post-double-image';
import { PostSingleImage } from './post-single-image';
import { PostSvgImage } from './post-svg-image';
import { PostSingleVideo } from './post-single-video';
import { PostImageGrid } from './post-image-grid';
import { PostMediaGrid } from './post-media-grid';
import { PostDivider } from './post-divider';
import { PostText } from './post-text';

export default function PostBodySections({ body }) {
  const sliceComponents = {
    text: PostText,
    single_video: PostSingleVideo,
    double_image: PostDoubleImage,
    single_image: PostSingleImage,
    svg_image_layout: PostSvgImage,
    image_grid: PostImageGrid,
    media_grid: PostMediaGrid,
    divider: PostDivider
  };

  return body.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent key={`slice-${index}`} data={slice} />;
    }
    return <pre key={index}>{slice.slice_type}</pre>;
  });
}
