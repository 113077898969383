import React from 'react';
import styled from 'styled-components';
import { Wrapper } from './style/global-styles';
import { LineAnimated } from './line-animated';

export function PostDivider({ data }) {
  return (
    <Wrap
      data-spacing-top={data.primary.spacing_top}
      data-spacing-bottom={data.primary.spacing_bottom}
    >
      <LineAnimated />
    </Wrap>
  );
}

const Wrap = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-spacing-top='true'] {
    margin-top: 6vw;
  }

  &[data-spacing-bottom='true'] {
    margin-bottom: 6vw;
  }
`;
