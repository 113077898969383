import React from "react";
import styled from "styled-components";
import Box from "./box";
import { Wrapper } from "./style/global-styles";
import VideoBox from "./video-box";

export function PostMediaGrid({ data: { primary, items } }) {
  return (
    <>
      {primary.double_image_title.text && (
        <SectionHeader>
          <h4 className="sequel">{primary.double_image_title.text}</h4>
        </SectionHeader>
      )}
      <GridWrapper>
        {items.map(({ grid_media, caption }, i) => {
          return (
            <div>
              <VideoBox key={i} className="image" video={grid_media} />
              {caption.text && <p className="caption">{caption.text}</p>}
            </div>
          );
        })}
      </GridWrapper>
    </>
  );
}

const SectionHeader = styled(Wrapper)`
  margin-top: 6vw;
  margin-bottom: 0;

  h4 {
    color: var(--post-accent-color);
    margin-bottom: 0;
  }
`;

const GridWrapper = styled(Box)`
  position: relative;
  margin: 8vw;
  width: calc(100vw - 16vw);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4vw;

  @media ${(props) => props.theme.device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .image {
    gap: 2rem;
    grid-column: span 1;
  }

  .caption {
    margin-top: 1rem;
    white-space: pre-wrap;
  }
`;
